import clsx from 'clsx';
import React from 'react';

interface FiveStarsProps {
  className?: string;
  score?: number;
}

const FiveStars: React.FC<FiveStarsProps> = ({ className, score = 5 }) => {
  return (
    <div className={clsx('relative flex items-center gap-1', className)}>
      {[...Array(5)].map((_, index) => (
        <div key={index} className="relative">
          <div
            className={clsx(
              "h-4 w-4 [mask-image:url('/icons/star-yellow.svg')] [mask-repeat:no-repeat] [mask-size:contain]"
            )}
            style={{
              background:
                score >= index + 1
                  ? '#FDB135'
                  : `linear-gradient(90deg, rgba(253,177,53,1) 0%, rgba(253,177,53,1) ${100 * (score - Math.floor(score))}%, #dadada ${100 * (score - Math.floor(score))}%)`,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default FiveStars;
