import AlterSwab from '@public/alter-swab-vertical.png';
import clsx from 'clsx';
import { motion, useAnimate, useInView } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef } from 'react';

import { buyDNAKitClickedAnalytics } from '@/utils/analytics';

interface StartJourneyCTAProps {
  bottomCornersColor?: string;
  ctaText?: string;
  scrollToTop?: boolean;
  topCornersColor?: string;
  variant?: 'default' | 'dna';
}

const MotionImage = motion(Image);

const StartJourneyCTA: React.FC<StartJourneyCTAProps> = ({
  bottomCornersColor,
  ctaText = 'Get your DNA fitness & health report. ',
  scrollToTop,
  topCornersColor,
  variant = 'default',
}) => {
  const isDefault = variant === 'default';
  const containerRef = useRef<HTMLDivElement>(null);
  const [swabRef, animate] = useAnimate();
  const isInView = useInView(containerRef, {
    amount: 0.75,
    once: true,
  });

  useEffect(() => {
    if (isInView) {
      animate(
        swabRef.current,
        { opacity: 1, translateY: 0 },
        {
          duration: 1,
          ease: 'easeOut',
        }
      );
    }
  }, [isInView]);

  return (
    <section
      ref={containerRef}
      className="relative"
      style={{
        background: `linear-gradient(${topCornersColor ?? 'white'} 50%, ${bottomCornersColor ?? 'black'})`,
      }}
    >
      <div
        className={clsx(
          'rounded-3xl  px-4 md:px-20 bg-gradient-to-br from-[#1d3476] to-[#3664B8]',
          {
            'h-[632px]': isDefault,
            'h-[550px] md:h-[632px]': !isDefault,
          }
        )}
      >
        <div className="width-1440">
          <p
            className={clsx(
              'md:text-[48px] md:leading-normal md:max-w-[20ch] text-white pt-10 md:pt-[120px]',
              {
                'mb-10 text-[32px]': isDefault,
                'mb-4 text-2xl': !isDefault,
              }
            )}
          >
            {isDefault ? (
              <>
                Start your personalized fitness journey
                <span className="text-[#F59617]"> today</span>.
              </>
            ) : (
              <>Get your DNA report today</>
            )}
          </p>

          {!isDefault ? (
            <p className="text-xl mb-6 md:mb-10 text-alter-gray-60/80 md:max-w-[35ch] lg:max-w-[50ch]">
              A DNA-driven approach to fitness, wellness, and stress management, in 48 hours.
            </p>
          ) : null}

          {!scrollToTop ? (
            <div className="px-4 py-4 md:px-8 bg-alter-bone rounded-full text-center max-w-fit">
              <Link
                href="/shop/swab-kit"
                className="text-[16px] text-alter-black hover:shadow-lg hover:opacity-80 transition-all"
                onClick={() => {
                  buyDNAKitClickedAnalytics('Swab Kit Footer CTA');
                }}
              >
                {ctaText}
              </Link>
            </div>
          ) : (
            <button
              className="py-[16px] px-[32px] rounded-full text-[16px] text-alter-black bg-alter-bone hover:shadow-lg hover:opacity-80 transition-all"
              onClick={() => {
                window.scrollTo({ top: 300, behavior: 'smooth' });
                buyDNAKitClickedAnalytics('Shop Page Footer');
              }}
            >
              {ctaText}
            </button>
          )}

          {!isDefault ? (
            <p className="text-alter-gray-60/60 text-sm mt-6">Free Shipping | Ships in 2-4 days</p>
          ) : null}

          <div className="flex flex-col items-center">
            <MotionImage
              ref={swabRef}
              initial={{ opacity: 0, translateY: 160 }}
              src={AlterSwab}
              alt="Alter swab"
              draggable={false}
              className={clsx('absolute bottom-0 object-contain', {
                'h-4/6 lg:h-full': isDefault,
                'h-[250px] sm:h-[280px] md:h-4/6 lg:h-full md:right-10 lg:right-20 xl:right-40':
                  !isDefault,
              })}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartJourneyCTA;
